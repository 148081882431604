
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios'
import regeneratorRuntime from "regenerator-runtime"

import './scss/styles.scss'


import etape1 from './gpx/etape1.gpx'
import etape2 from './gpx/etape2.gpx'
import etape3 from './gpx/etape3.gpx'
import etape4 from './gpx/etape4.gpx'
import road from './gpx/road.gpx'

import etapesArray from './data/etapes.json'
import mapstyle from './data/mapstyle.json'
import { isConstructorDeclaration } from 'typescript';


document.addEventListener("DOMContentLoaded", function() {
    console.log("DOM loaded");

    AOS.init();

        

  
    // Ouverture de Modal

    const body = document.querySelector('body');

    const etapes = document.querySelectorAll('.etapes .etape')
    const modalEtape = document.querySelector('.modal-etape')
    const closeModalEtape = document.querySelector('.modal-etape .overlay')
    const etapeModalPrev = document.querySelector('.modal-etape .prev')
    const etapeModalNext = document.querySelector('.modal-etape .next')

    etapes.forEach(etape => {
        etape.addEventListener('click', function(e) {
            e.preventDefault()
           const step = this.getAttribute('data-step')
           openModal(step)
           
        })
        
    })

    closeModalEtape.addEventListener('click', function() {
        modalEtape.classList.remove('active')
        body.classList.remove('modal-open')
      
    })

    // Navigation dans les étapes
    etapeModalPrev.addEventListener('click',function(){
        fillEtape(parseInt(modalEtape.getAttribute('data-step'))-1)
    })
    etapeModalNext.addEventListener('click',function(){
        fillEtape(parseInt(modalEtape.getAttribute('data-step'))+1)
    })
    const fillEtape = (step) => {
        step = parseInt(step)
        let etape = etapesArray.find(etape => etape.id == step)
        let etapePrev = etapesArray.find(etape => etape.id == step-1)
        let etapeNext = etapesArray.find(etape => etape.id == step+1)
        modalEtape.setAttribute('data-step',step)
        const etapeNumber = document.querySelector('.modal-etape p.etape') 
        const etapeTitle = document.querySelector('.modal-etape h3') 
        const etapeInfos = document.querySelector('.modal-etape .infos') 
        const etapeDivPrev = document.querySelector('.modal-etape .prev') 
        const etapeDivNext = document.querySelector('.modal-etape .next') 

        initMapEtape(step)
        etapeNumber.innerHTML = `Etape ${etape.id}`
        etapeTitle.innerHTML = etape.nom
        etapeInfos.innerHTML = etape.description

        if(etapePrev) {
            etapeDivPrev.innerHTML = `#${etapePrev.id} - ${etapePrev.nom}`
        } else {
            etapeDivPrev.innerHTML = ''
        }

        if(etapeNext) {
            etapeDivNext.innerHTML = `#${etapeNext.id} - ${etapeNext.nom}`
        } else {
            etapeDivNext.innerHTML = ''
        }
    }


    function openModal(step){
        modalEtape.setAttribute('data-step', step)
        fillEtape(step)
        modalEtape.classList.add('active')
        body.classList.add('modal-open')

    }


    // Map 

    function initMapSuivi(){
        let map = new google.maps.Map(document.getElementById('map-suivi'), {
            zoom: 6,
            center: {lat: 46.0811608, lng: 2.3991583},
            styles: mapstyle,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl:false,
            fullscreenControl: false,
        });

        addGpx(etape1,map,'#584B4F',false,1,0.5)
        addGpx(etape2,map,'#584B4F',false,2,0.5)
        addGpx(etape3,map,'#584B4F',false,3,0.5)
        addGpx(etape4,map,'#584B4F',false,4,0.5)
        addGpx(road,map,'#516422',false,1,1)

        //displayWahooData(map)
    }

   

    function initMapEtape(step) {
        // The location of Uluru
        const rennes = { lat: 	45.784832, lng: 3.114432 };
        // The map, centered at Uluru
        const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 6,
        center: rennes,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl:false,
        fullscreenControl: false,
        });
        let gpx;
        if(step == 1) {
            gpx = etape1
        }
        if(step == 2) {
            gpx = etape2
        }
        if(step == 3) {
            gpx = etape3
        }
        if(step == 4) {
            gpx = etape4
        }


        map.setOptions({ styles: mapstyle });

        addGpx(gpx,map,'#584B4B',true,step,1)

        
    }

    function addGpx(gpx,map,color,center,etape,strokeOpacity){

        fetch(gpx)
                .then(response => response.text())
                .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
                //.then(data => console.log(data))
                .then(doc =>
                {
                    var points = [];
                    var bounds = new google.maps.LatLngBounds();
                
                    const nodes = [...doc.getElementsByTagName('trkpt')];
                    nodes.forEach(node =>
                    {
                        var lat = node.getAttribute("lat");
                        var lon = node.getAttribute("lon");
                        //console.log(lat);
                        
                        var p = new google.maps.LatLng(lat, lon);
                        points.push(p);
                        bounds.extend(p);
                    })
                    
                        var poly = new google.maps.Polyline({
                             path: points,
                             strokeColor: color,
                             strokeOpacity: strokeOpacity,
                             strokeWeight: 4
                        });
                        poly.setMap(map);
                       
                        // Click & Hover Event

                        if(etape && strokeOpacity != 1){

                            google.maps.event.addListener(poly,'click',function(e){
                                console.log('Click polyline')
                                openModal(etape)
                            })
                            google.maps.event.addListener(poly,'mouseover',function(e){
                                poly.setOptions({ strokeOpacity: 1 });
                                
                            })

                            google.maps.event.addListener(poly,'mouseout',function(e){
                                poly.setOptions({ strokeOpacity: 0.5 });
                            })

                        }
                        // fit bounds to track
                        if(center){
                            map.fitBounds(bounds);
                        }
                      


                })

    }

    

    // Flux Instagram


    let token_instagram = 'IGQVJYOXNGbVlKRDVUaXRHU1Y4S0ctVzVhOC1ROW9fRnVLRnU4VFBUYk5oV2FPc2htR3dwMzRGWVhRaTlGWUNEQmplajN4RXVfZAEdFdE41MWUxMjd3aklfOVREalJZAa1RVcDZAvUnUwQ0ZAHdUZAQRjJXOQZDZD'
    const instagram = document.querySelector('.instagram')
    const flux = document.querySelector('.flux')
    const fetchInstagram = async () => {
        const fluxInstagram = await axios.get('https://graph.instagram.com/me/media?fields=media_type,media_url,caption,thumbnail_url,permalink&limit=60&access_token='+token_instagram).then(res => res.data)
        flux.innerHTML='';
        fluxInstagram.data.forEach(post => {
            const div = document.createElement('div')
            div.classList.add('post')
            div.setAttribute('data-id-instagram',post.id)
            const media_url = post.media_type != 'VIDEO' ? post.media_url : post.thumbnail_url
            if(post.media_type == 'VIDEO'){
                div.classList.add('video')
            }
            div.innerHTML = `
                <a href="${post.permalink}" target="_blank"><img src="${media_url}" alt="">
                ${post.caption ? `<div class="caption"><p>${post.caption}</p></div>` : ''}</a>
            `
            flux.appendChild(div)
        })



    } 
    fetchInstagram()

    

    initMapSuivi();

    async function getDataApi(){
        let data = await axios.get('https://api.udysee.bike/').then(res => {return res.data})
        return data;
    }


    /*async function getWahooData(){

        let dataApi = await getDataApi();
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+dataApi.access_token_wahoo
        }

        const data = await axios.get('https://api.wahooligan.com/v1/workouts?per_page=3000',{headers}).then(res => {return res.data})
        const now = new Date()
        
        let params = new URLSearchParams(document.location.search);
        let isTest = Boolean(params.get("test"));
        const workouts = isTest ? data.workouts : data.workouts.filter(workout => workout.created_at > '2022-05-15T00:00:00.000Z')



        let distance = 0
        let time = 0
        let asc = 0
        let gpxUrls = []

        workouts.forEach(workout => {
            if(workout.workout_summary && workout.workout_summary.distance_accum){
                distance = distance + parseFloat(workout.workout_summary.distance_accum || 0)
            }

            if(workout.workout_summary && workout.workout_summary.duration_active_accum){
                time = time + parseFloat(workout.workout_summary.duration_active_accum|| 0)
            }

            if(workout.workout_summary && workout.workout_summary.ascent_accum){
                asc = asc + parseFloat(workout.workout_summary.ascent_accum|| 0)
            }

            if(workout.workout_summary && workout.workout_summary.file.url){  
                gpxUrls.push(workout.workout_summary.file.url)
            }
        })



        return {distance:distance,time:time,asc:asc,gpxUrls:gpxUrls}
    }
    /*



    /*async function displayWahooData(map){
        let metrics = await getWahooData();

        console.log(metrics);

        let spanPercent = document.querySelector('div[data-info=percent] span')
        let spanDistance = document.querySelector('div[data-info=distance] span')
        let spanAsc = document.querySelector('div[data-info=asc] span')
        let spanTime = document.querySelector('div[data-info=time] span')

        const kmtotal = 2230

        spanPercent.innerHTML = Math.round((metrics.distance/1000)*100 / kmtotal) + '%'
        spanDistance.innerHTML = Math.round(metrics.distance/1000)
        spanAsc.innerHTML = Math.round(metrics.asc)
        spanTime.innerHTML = Math.round(metrics.time/3600)+'h'


        metrics.gpxUrls.forEach(url => {
            // let fitToGpxLink = 'https://api.udysee.bike/fittogpx/convert.php?url='+url;
            // addGpx(fitToGpxLink,map,'#516422',false,1,1)
        })


    }*/

    

});

